.article-editor-wrapper {
  width: 100%;
  height: 90%;
}

.article-editor {
  height: calc(100vh - 300px);
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 2px;
}
